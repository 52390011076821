"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "text-body-2" };
var _hoisted_2 = { class: "mb-2" };
var _hoisted_3 = { key: 0 };
var _hoisted_4 = ["href"];
var _hoisted_5 = { key: 1 };
var _hoisted_6 = ["href"];
var _hoisted_7 = { key: 2 };
var _hoisted_8 = ["href"];
var _hoisted_9 = { class: "text-body-2" };
var _hoisted_10 = { key: 0 };
var _hoisted_11 = { key: 1 };
var _hoisted_12 = { class: "text-body-2 quick-links" };
var _hoisted_13 = ["href"];
var _hoisted_14 = { class: "text-center text-body-2" };
var _hoisted_15 = {
    key: 0,
    class: "text-center text-caption text-medium-emphasis mt-1"
};
var vue_3 = require("vue");
var vuex_1 = require("vuex");
var AddressView_vue_1 = require("./AddressView.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'PortalFooter',
    setup: function (__props) {
        var store = (0, vuex_1.useStore)();
        var companyDetails = (0, vue_3.computed)(function () { return store.getters['companyStore/companyDetails']; });
        var businessHours = (0, vue_3.computed)(function () { return store.getters['companyStore/businessHours']; });
        var footerSettings = (0, vue_3.computed)(function () { var _a; return (_a = companyDetails.value) === null || _a === void 0 ? void 0 : _a.footerSettings; });
        var sortedQuickLinks = (0, vue_3.computed)(function () {
            var _a;
            return __spreadArray([], (((_a = companyDetails.value) === null || _a === void 0 ? void 0 : _a.quickLinks) || []), true).sort(function (a, b) { return a.orderIndex - b.orderIndex; });
        });
        var isToday = function (day) {
            var today = new Date().toLocaleDateString('en-US', { weekday: 'long' });
            return day === today;
        };
        var getSocialIcon = function (link) {
            var domain = new URL(link).hostname.toLowerCase();
            var socialIcons = {
                'facebook.com': 'mdi-facebook',
                'twitter.com': 'mdi-twitter',
                'instagram.com': 'mdi-instagram',
                'linkedin.com': 'mdi-linkedin',
                'youtube.com': 'mdi-youtube',
            };
            for (var _i = 0, _a = Object.entries(socialIcons); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                if (domain.includes(key))
                    return value;
            }
            return 'mdi-web';
        };
        return function (_ctx, _cache) {
            var _component_v_icon = (0, vue_2.resolveComponent)("v-icon");
            var _component_v_col = (0, vue_2.resolveComponent)("v-col");
            var _component_v_row = (0, vue_2.resolveComponent)("v-row");
            var _component_v_card_text = (0, vue_2.resolveComponent)("v-card-text");
            var _component_v_btn = (0, vue_2.resolveComponent)("v-btn");
            var _component_v_divider = (0, vue_2.resolveComponent)("v-divider");
            var _component_v_card = (0, vue_2.resolveComponent)("v-card");
            var _component_v_footer = (0, vue_2.resolveComponent)("v-footer");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_footer, { class: "mt-6" }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)(_component_v_card, {
                        flat: "",
                        width: "100%",
                        class: "text-center"
                    }, {
                        default: (0, vue_2.withCtx)(function () {
                            var _a, _b;
                            return [
                                (0, vue_2.createVNode)(_component_v_card_text, null, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)(_component_v_row, { justify: "center" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(_component_v_col, {
                                                    cols: "12",
                                                    md: "4"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () {
                                                        var _a, _b, _c;
                                                        return [
                                                            _cache[0] || (_cache[0] = (0, vue_2.createElementVNode)("h3", { class: "text-h6 mb-2" }, "Contact Us", -1)),
                                                            (0, vue_2.createElementVNode)("div", _hoisted_1, [
                                                                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                                                                    (companyDetails.value)
                                                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(AddressView_vue_1.default, {
                                                                            key: 0,
                                                                            companyDetails: companyDetails.value
                                                                        }, null, 8, ["companyDetails"]))
                                                                        : (0, vue_2.createCommentVNode)("", true)
                                                                ]),
                                                                ((_a = companyDetails.value) === null || _a === void 0 ? void 0 : _a.phoneNumber)
                                                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_3, [
                                                                        (0, vue_2.createVNode)(_component_v_icon, {
                                                                            icon: "mdi-phone",
                                                                            size: "small",
                                                                            class: "me-1"
                                                                        }),
                                                                        (0, vue_2.createElementVNode)("a", {
                                                                            href: "tel:".concat(companyDetails.value.phoneNumber),
                                                                            class: "text-decoration-none"
                                                                        }, (0, vue_2.toDisplayString)(companyDetails.value.phoneNumber), 9, _hoisted_4)
                                                                    ]))
                                                                    : (0, vue_2.createCommentVNode)("", true),
                                                                ((_b = companyDetails.value) === null || _b === void 0 ? void 0 : _b.email)
                                                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_5, [
                                                                        (0, vue_2.createVNode)(_component_v_icon, {
                                                                            icon: "mdi-email",
                                                                            size: "small",
                                                                            class: "me-1"
                                                                        }),
                                                                        (0, vue_2.createElementVNode)("a", {
                                                                            href: "mailto:".concat(companyDetails.value.email),
                                                                            class: "text-decoration-none"
                                                                        }, (0, vue_2.toDisplayString)(companyDetails.value.email), 9, _hoisted_6)
                                                                    ]))
                                                                    : (0, vue_2.createCommentVNode)("", true),
                                                                ((_c = companyDetails.value) === null || _c === void 0 ? void 0 : _c.website)
                                                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_7, [
                                                                        (0, vue_2.createVNode)(_component_v_icon, {
                                                                            icon: "mdi-web",
                                                                            size: "small",
                                                                            class: "me-1"
                                                                        }),
                                                                        (0, vue_2.createElementVNode)("a", {
                                                                            href: companyDetails.value.website,
                                                                            target: "_blank",
                                                                            class: "text-decoration-none"
                                                                        }, (0, vue_2.toDisplayString)(companyDetails.value.website), 9, _hoisted_8)
                                                                    ]))
                                                                    : (0, vue_2.createCommentVNode)("", true)
                                                            ])
                                                        ];
                                                    }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_v_col, {
                                                    cols: "12",
                                                    md: "4"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        _cache[1] || (_cache[1] = (0, vue_2.createElementVNode)("h3", { class: "text-h6 mb-2" }, "Business Hours", -1)),
                                                        (0, vue_2.createElementVNode)("div", _hoisted_9, [
                                                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(businessHours.value, function (hours) {
                                                                return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                                                    key: hours.day,
                                                                    class: (0, vue_2.normalizeClass)({ 'font-weight-medium': isToday(hours.day) })
                                                                }, [
                                                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(hours.day) + ": ", 1),
                                                                    (hours.isClosed)
                                                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_10, "Closed"))
                                                                        : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_11, (0, vue_2.toDisplayString)(hours.openTime) + " - " + (0, vue_2.toDisplayString)(hours.closeTime), 1))
                                                                ], 2));
                                                            }), 128))
                                                        ])
                                                    ]; }),
                                                    _: 1
                                                }),
                                                (0, vue_2.createVNode)(_component_v_col, {
                                                    cols: "12",
                                                    md: "4"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        _cache[2] || (_cache[2] = (0, vue_2.createElementVNode)("h3", { class: "text-h6 mb-2" }, "Quick Links", -1)),
                                                        (0, vue_2.createElementVNode)("div", _hoisted_12, [
                                                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(sortedQuickLinks.value, function (link) {
                                                                return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                                                    key: link.id,
                                                                    class: "quick-link-item"
                                                                }, [
                                                                    (0, vue_2.createElementVNode)("a", {
                                                                        href: link.url,
                                                                        class: "text-decoration-none d-flex align-center",
                                                                        target: "_blank"
                                                                    }, [
                                                                        (link.icon)
                                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_icon, {
                                                                                key: 0,
                                                                                icon: link.icon,
                                                                                size: "small",
                                                                                class: "me-1"
                                                                            }, null, 8, ["icon"]))
                                                                            : (0, vue_2.createCommentVNode)("", true),
                                                                        (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(link.title), 1)
                                                                    ], 8, _hoisted_13)
                                                                ]));
                                                            }), 128))
                                                        ])
                                                    ]; }),
                                                    _: 1
                                                })
                                            ]; }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                }),
                                ((_b = (_a = footerSettings.value) === null || _a === void 0 ? void 0 : _a.socialLinks) === null || _b === void 0 ? void 0 : _b.length)
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_card_text, {
                                        key: 0,
                                        class: "social-links"
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(footerSettings.value.socialLinks, function (link, index) {
                                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_btn, {
                                                    key: index,
                                                    href: link,
                                                    target: "_blank",
                                                    variant: "text",
                                                    icon: "",
                                                    class: "mx-2"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createVNode)(_component_v_icon, {
                                                            icon: getSocialIcon(link)
                                                        }, null, 8, ["icon"])
                                                    ]; }),
                                                    _: 2
                                                }, 1032, ["href"]));
                                            }), 128))
                                        ]; }),
                                        _: 1
                                    }))
                                    : (0, vue_2.createCommentVNode)("", true),
                                (0, vue_2.createVNode)(_component_v_divider),
                                (0, vue_2.createVNode)(_component_v_card_text, null, {
                                    default: (0, vue_2.withCtx)(function () {
                                        var _a, _b, _c;
                                        return [
                                            (0, vue_2.createElementVNode)("div", _hoisted_14, (0, vue_2.toDisplayString)(((_a = footerSettings.value) === null || _a === void 0 ? void 0 : _a.copyrightText) || "\u00A9 ".concat(new Date().getFullYear(), " ").concat((_b = companyDetails.value) === null || _b === void 0 ? void 0 : _b.companyName, ". All rights reserved.")), 1),
                                            ((_c = footerSettings.value) === null || _c === void 0 ? void 0 : _c.additionalInfo)
                                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_15, (0, vue_2.toDisplayString)(footerSettings.value.additionalInfo), 1))
                                                : (0, vue_2.createCommentVNode)("", true)
                                        ];
                                    }),
                                    _: 1
                                })
                            ];
                        }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
