"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
var vuex_1 = require("vuex");
exports.default = (0, vue_1.defineComponent)({
    __name: 'UserSettingsDialog',
    setup: function (__props) {
        var _this = this;
        var store = (0, vuex_1.useStore)();
        var dialog = (0, vue_3.ref)(false);
        var isValid = (0, vue_3.ref)(false);
        var form = (0, vue_3.ref)(null);
        var userSettings = (0, vue_3.computed)(function () { return store.getters['userStore/userSettings']; });
        var loading = (0, vue_3.computed)(function () { return store.getters['userStore/loading']; });
        var formData = (0, vue_3.ref)({
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        });
        (0, vue_3.watch)(userSettings, function (newSettings) {
            if (newSettings) {
                formData.value = __assign({}, newSettings);
            }
        }, { immediate: true });
        var saveSettings = function () { return __awaiter(_this, void 0, void 0, function () {
            var success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isValid.value)
                            return [2 /*return*/];
                        return [4 /*yield*/, store.dispatch('userStore/updateUserSettings', formData.value)];
                    case 1:
                        success = _a.sent();
                        if (success) {
                            dialog.value = false;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        return function (_ctx, _cache) {
            var _component_v_btn = (0, vue_2.resolveComponent)("v-btn");
            var _component_v_card_title = (0, vue_2.resolveComponent)("v-card-title");
            var _component_v_text_field = (0, vue_2.resolveComponent)("v-text-field");
            var _component_v_col = (0, vue_2.resolveComponent)("v-col");
            var _component_v_row = (0, vue_2.resolveComponent)("v-row");
            var _component_v_form = (0, vue_2.resolveComponent)("v-form");
            var _component_v_card_text = (0, vue_2.resolveComponent)("v-card-text");
            var _component_v_spacer = (0, vue_2.resolveComponent)("v-spacer");
            var _component_v_card_actions = (0, vue_2.resolveComponent)("v-card-actions");
            var _component_v_card = (0, vue_2.resolveComponent)("v-card");
            var _component_v_dialog = (0, vue_2.resolveComponent)("v-dialog");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_dialog, {
                modelValue: dialog.value,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) { return ((dialog).value = $event); }),
                "max-width": "500"
            }, {
                activator: (0, vue_2.withCtx)(function (_a) {
                    var props = _a.props;
                    return [
                        (0, vue_2.createVNode)(_component_v_btn, (0, vue_2.mergeProps)(props, {
                            variant: "text",
                            class: "settings-button",
                            "prepend-icon": "mdi-account-cog"
                        }), {
                            default: (0, vue_2.withCtx)(function () { return _cache[7] || (_cache[7] = [
                                (0, vue_2.createTextVNode)(" Settings ")
                            ]); }),
                            _: 2
                        }, 1040)
                    ];
                }),
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)(_component_v_card, null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)(_component_v_card_title, { class: "d-flex align-center justify-space-between pa-4" }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    _cache[8] || (_cache[8] = (0, vue_2.createElementVNode)("span", null, "User Settings", -1)),
                                    (0, vue_2.createVNode)(_component_v_btn, {
                                        icon: "mdi-close",
                                        variant: "text",
                                        onClick: _cache[0] || (_cache[0] = function ($event) { return (dialog.value = false); })
                                    })
                                ]; }),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_v_card_text, null, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)(_component_v_form, {
                                        ref_key: "form",
                                        ref: form,
                                        modelValue: isValid.value,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) { return ((isValid).value = $event); }),
                                        onSubmit: (0, vue_2.withModifiers)(saveSettings, ["prevent"])
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)(_component_v_row, null, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createVNode)(_component_v_col, {
                                                        cols: "12",
                                                        sm: "6"
                                                    }, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (0, vue_2.createVNode)(_component_v_text_field, {
                                                                modelValue: formData.value.firstName,
                                                                "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((formData.value.firstName) = $event); }),
                                                                label: "First Name",
                                                                rules: [function (v) { return !!v || 'First name is required'; }]
                                                            }, null, 8, ["modelValue", "rules"])
                                                        ]; }),
                                                        _: 1
                                                    }),
                                                    (0, vue_2.createVNode)(_component_v_col, {
                                                        cols: "12",
                                                        sm: "6"
                                                    }, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (0, vue_2.createVNode)(_component_v_text_field, {
                                                                modelValue: formData.value.lastName,
                                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((formData.value.lastName) = $event); }),
                                                                label: "Last Name",
                                                                rules: [function (v) { return !!v || 'Last name is required'; }]
                                                            }, null, 8, ["modelValue", "rules"])
                                                        ]; }),
                                                        _: 1
                                                    }),
                                                    (0, vue_2.createVNode)(_component_v_col, { cols: "12" }, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (0, vue_2.createVNode)(_component_v_text_field, {
                                                                modelValue: formData.value.email,
                                                                "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((formData.value.email) = $event); }),
                                                                label: "Email",
                                                                type: "email",
                                                                rules: [
                                                                    function (v) { return !!v || 'Email is required'; },
                                                                    function (v) { return /.+@.+\..+/.test(v) || 'Email must be valid'; }
                                                                ]
                                                            }, null, 8, ["modelValue", "rules"])
                                                        ]; }),
                                                        _: 1
                                                    }),
                                                    (0, vue_2.createVNode)(_component_v_col, { cols: "12" }, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (0, vue_2.createVNode)(_component_v_text_field, {
                                                                modelValue: formData.value.phone,
                                                                "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return ((formData.value.phone) = $event); }),
                                                                label: "Phone",
                                                                rules: [
                                                                    function (v) { return !!v || 'Phone is required'; },
                                                                    function (v) { return /^\+?[\d\s-()]+$/.test(v) || 'Phone must be valid'; }
                                                                ]
                                                            }, null, 8, ["modelValue", "rules"])
                                                        ]; }),
                                                        _: 1
                                                    })
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    }, 8, ["modelValue"])
                                ]; }),
                                _: 1
                            }),
                            (0, vue_2.createVNode)(_component_v_card_actions, { class: "pa-4" }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)(_component_v_spacer),
                                    (0, vue_2.createVNode)(_component_v_btn, {
                                        color: "primary",
                                        loading: loading.value,
                                        disabled: !isValid.value,
                                        onClick: saveSettings
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return _cache[9] || (_cache[9] = [
                                            (0, vue_2.createTextVNode)(" Save Changes ")
                                        ]); }),
                                        _: 1
                                    }, 8, ["loading", "disabled"])
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }, 8, ["modelValue"]));
        };
    }
});
