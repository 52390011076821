"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "welcome-section" };
var _hoisted_2 = { class: "text-h3 font-weight-bold mb-2 welcome-title" };
var _hoisted_3 = {
    key: 0,
    class: "user-actions"
};
var _hoisted_4 = { class: "contact-item d-flex align-center mb-4" };
var _hoisted_5 = { class: "contact-item d-flex align-center" };
var _hoisted_6 = ["href"];
var vue_3 = require("vue");
var vue_router_1 = require("vue-router");
var vuex_1 = require("vuex");
var UserSettingsDialog_vue_1 = require("./UserSettingsDialog.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'PortalHeader',
    setup: function (__props) {
        var store = (0, vuex_1.useStore)();
        var router = (0, vue_router_1.useRouter)();
        var isHovered = (0, vue_3.ref)(false);
        var isAuth = (0, vue_3.computed)(function () { return store.getters.isAuthenticated; });
        var companyDetails = (0, vue_3.computed)(function () { return store.getters['companyStore/companyDetails']; });
        var todayBusinessHours = (0, vue_3.computed)(function () {
            var hours = store.getters['companyStore/businessHours'];
            var today = new Date().toLocaleDateString('en-US', { weekday: 'long' });
            return hours === null || hours === void 0 ? void 0 : hours.filter(function (h) { return h.day === today; });
        });
        var handleLogout = function () {
            store.dispatch('logout');
            store.dispatch('userStore/clearUserSettings');
            router.replace('/login');
        };
        (0, vue_3.onMounted)(function () {
            store.dispatch('companyStore/fetchCompanyDetails');
            store.dispatch('userStore/fetchUserSettings');
        });
        return function (_ctx, _cache) {
            var _component_v_col = (0, vue_2.resolveComponent)("v-col");
            var _component_v_icon = (0, vue_2.resolveComponent)("v-icon");
            var _component_v_btn = (0, vue_2.resolveComponent)("v-btn");
            var _component_v_card_text = (0, vue_2.resolveComponent)("v-card-text");
            var _component_v_card = (0, vue_2.resolveComponent)("v-card");
            var _component_v_row = (0, vue_2.resolveComponent)("v-row");
            var _component_v_container = (0, vue_2.resolveComponent)("v-container");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_card, {
                class: "portal-header mb-6",
                elevation: "0"
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)(_component_v_container, null, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)(_component_v_row, {
                                align: "center",
                                class: "header-content"
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)(_component_v_col, {
                                        cols: "12",
                                        md: "7",
                                        class: "header-text"
                                    }, {
                                        default: (0, vue_2.withCtx)(function () {
                                            var _a;
                                            return [
                                                (0, vue_2.createElementVNode)("div", _hoisted_1, [
                                                    (0, vue_2.createElementVNode)("h1", _hoisted_2, [
                                                        (0, vue_2.createTextVNode)(" Welcome to " + (0, vue_2.toDisplayString)((_a = companyDetails.value) === null || _a === void 0 ? void 0 : _a.companyName) + " ", 1),
                                                        _cache[2] || (_cache[2] = (0, vue_2.createElementVNode)("span", { class: "gradient-text" }, "Service Portal", -1))
                                                    ]),
                                                    _cache[3] || (_cache[3] = (0, vue_2.createElementVNode)("p", { class: "text-body-1 mb-4 welcome-description" }, " Track your device repairs and communicate with our technicians in real-time. We're here to help you every step of the way. ", -1))
                                                ])
                                            ];
                                        }),
                                        _: 1
                                    }),
                                    (0, vue_2.createVNode)(_component_v_col, {
                                        cols: "12",
                                        md: "5",
                                        class: "contact-section"
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)(_component_v_card, {
                                                class: "contact-card",
                                                elevation: "3"
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (isAuth.value)
                                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_3, [
                                                            (0, vue_2.createVNode)(UserSettingsDialog_vue_1.default),
                                                            (0, vue_2.createVNode)(_component_v_btn, {
                                                                color: "red",
                                                                variant: "outlined",
                                                                class: "logout-button",
                                                                onMouseenter: _cache[0] || (_cache[0] = function ($event) { return (isHovered.value = true); }),
                                                                onMouseleave: _cache[1] || (_cache[1] = function ($event) { return (isHovered.value = false); }),
                                                                onClick: handleLogout
                                                            }, {
                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createVNode)(_component_v_icon, {
                                                                        icon: "mdi-logout",
                                                                        class: (0, vue_2.normalizeClass)(["login-icon", { 'rotate-icon': isHovered.value }])
                                                                    }, null, 8, ["class"]),
                                                                    (0, vue_2.createElementVNode)("span", {
                                                                        class: (0, vue_2.normalizeClass)(["logout-text", { 'text-white': isHovered.value }])
                                                                    }, " Logout ", 2)
                                                                ]; }),
                                                                _: 1
                                                            })
                                                        ]))
                                                        : (0, vue_2.createCommentVNode)("", true),
                                                    (0, vue_2.createVNode)(_component_v_card_text, null, {
                                                        default: (0, vue_2.withCtx)(function () {
                                                            var _a, _b;
                                                            return [
                                                                (0, vue_2.createElementVNode)("div", _hoisted_4, [
                                                                    (0, vue_2.createVNode)(_component_v_icon, {
                                                                        icon: "mdi-clock-outline",
                                                                        size: "large",
                                                                        color: "primary",
                                                                        class: "me-3"
                                                                    }),
                                                                    (0, vue_2.createElementVNode)("div", null, [
                                                                        _cache[4] || (_cache[4] = (0, vue_2.createElementVNode)("div", { class: "text-subtitle-1 font-weight-medium" }, "Business Hours", -1)),
                                                                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(todayBusinessHours.value, function (hours) {
                                                                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                                                                key: hours.day,
                                                                                class: "text-body-2 text-medium-emphasis"
                                                                            }, (0, vue_2.toDisplayString)(hours.isClosed ? 'Closed' : "".concat(hours.openTime, "-").concat(hours.closeTime)), 1));
                                                                        }), 128))
                                                                    ])
                                                                ]),
                                                                (0, vue_2.createElementVNode)("div", _hoisted_5, [
                                                                    (0, vue_2.createVNode)(_component_v_icon, {
                                                                        icon: "mdi-phone",
                                                                        size: "large",
                                                                        color: "primary",
                                                                        class: "me-3"
                                                                    }),
                                                                    (0, vue_2.createElementVNode)("div", null, [
                                                                        _cache[5] || (_cache[5] = (0, vue_2.createElementVNode)("div", { class: "text-subtitle-1 font-weight-medium" }, "Emergency Support", -1)),
                                                                        (0, vue_2.createElementVNode)("a", {
                                                                            href: "tel:".concat((_a = companyDetails.value) === null || _a === void 0 ? void 0 : _a.phoneNumber),
                                                                            class: "text-body-2 text-primary text-decoration-none"
                                                                        }, (0, vue_2.toDisplayString)((_b = companyDetails.value) === null || _b === void 0 ? void 0 : _b.phoneNumber), 9, _hoisted_6)
                                                                    ])
                                                                ])
                                                            ];
                                                        }),
                                                        _: 1
                                                    })
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    })
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
