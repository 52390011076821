"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
var vue_router_1 = require("vue-router");
var base_1 = require("../store/base");
exports.default = (0, vue_1.defineComponent)({
    __name: 'LoginPage',
    setup: function (__props) {
        var _this = this;
        var router = (0, vue_router_1.useRouter)();
        var contact = (0, vue_3.ref)('');
        var verificationCode = (0, vue_3.ref)('');
        var codeSent = (0, vue_3.ref)(false);
        var handleSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
            var success, success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!codeSent.value) return [3 /*break*/, 2];
                        return [4 /*yield*/, base_1.store.dispatch('sendCodeRequest', contact.value)];
                    case 1:
                        success = _a.sent();
                        if (success) {
                            codeSent.value = true;
                        }
                        return [3 /*break*/, 5];
                    case 2: return [4 /*yield*/, base_1.store.dispatch('authorizeAndStoreUser', {
                            contact: contact.value,
                            code: verificationCode.value
                        })];
                    case 3:
                        success = _a.sent();
                        if (!success) return [3 /*break*/, 5];
                        return [4 /*yield*/, Promise.all([
                                router.replace('/ticket'),
                                base_1.store.dispatch('ticketStore/fetchTickets')
                            ])];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        return function (_ctx, _cache) {
            var _component_v_toolbar_title = (0, vue_2.resolveComponent)("v-toolbar-title");
            var _component_v_toolbar = (0, vue_2.resolveComponent)("v-toolbar");
            var _component_v_text_field = (0, vue_2.resolveComponent)("v-text-field");
            var _component_v_form = (0, vue_2.resolveComponent)("v-form");
            var _component_v_card_text = (0, vue_2.resolveComponent)("v-card-text");
            var _component_v_spacer = (0, vue_2.resolveComponent)("v-spacer");
            var _component_v_btn = (0, vue_2.resolveComponent)("v-btn");
            var _component_v_card_actions = (0, vue_2.resolveComponent)("v-card-actions");
            var _component_v_card = (0, vue_2.resolveComponent)("v-card");
            var _component_v_col = (0, vue_2.resolveComponent)("v-col");
            var _component_v_row = (0, vue_2.resolveComponent)("v-row");
            var _component_v_container = (0, vue_2.resolveComponent)("v-container");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_container, {
                fluid: "",
                "fill-height": ""
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)(_component_v_row, {
                        align: "center",
                        justify: "center"
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)(_component_v_col, {
                                cols: "12",
                                sm: "8",
                                md: "4"
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)(_component_v_card, { class: "elevation-12" }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)(_component_v_toolbar, {
                                                color: "primary",
                                                dark: "",
                                                flat: ""
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createVNode)(_component_v_toolbar_title, null, {
                                                        default: (0, vue_2.withCtx)(function () { return _cache[2] || (_cache[2] = [
                                                            (0, vue_2.createTextVNode)("Login")
                                                        ]); }),
                                                        _: 1
                                                    })
                                                ]; }),
                                                _: 1
                                            }),
                                            (0, vue_2.createVNode)(_component_v_card_text, null, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createVNode)(_component_v_form, {
                                                        onSubmit: (0, vue_2.withModifiers)(handleSubmit, ["prevent"])
                                                    }, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (!codeSent.value)
                                                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_text_field, {
                                                                    key: 0,
                                                                    modelValue: contact.value,
                                                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((contact).value = $event); }),
                                                                    label: "Email or Phone",
                                                                    name: "contact",
                                                                    "prepend-icon": "mdi-account",
                                                                    type: "text",
                                                                    required: ""
                                                                }, null, 8, ["modelValue"]))
                                                                : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_text_field, {
                                                                    key: 1,
                                                                    modelValue: verificationCode.value,
                                                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((verificationCode).value = $event); }),
                                                                    label: "Verification Code",
                                                                    name: "code",
                                                                    "prepend-icon": "mdi-lock",
                                                                    type: "text",
                                                                    required: ""
                                                                }, null, 8, ["modelValue"]))
                                                        ]; }),
                                                        _: 1
                                                    })
                                                ]; }),
                                                _: 1
                                            }),
                                            (0, vue_2.createVNode)(_component_v_card_actions, null, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createVNode)(_component_v_spacer),
                                                    (0, vue_2.createVNode)(_component_v_btn, {
                                                        color: "primary",
                                                        onClick: handleSubmit
                                                    }, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(codeSent.value ? 'Verify' : 'Send Code'), 1)
                                                        ]; }),
                                                        _: 1
                                                    })
                                                ]; }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    })
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }));
        };
    }
});
