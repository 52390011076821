"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var base_1 = require("./store/base");
var App_vue_1 = require("./App.vue");
require("vuetify/styles");
var vuetify_1 = require("vuetify");
var components = require("vuetify/components");
var directives = require("vuetify/directives");
var mdi_1 = require("vuetify/iconsets/mdi");
require("@mdi/font/css/materialdesignicons.css");
var router_1 = require("./router");
var vuetify = (0, vuetify_1.createVuetify)({
    components: components,
    directives: directives,
    icons: {
        defaultSet: 'mdi',
        aliases: mdi_1.aliases,
        sets: {
            mdi: mdi_1.mdi,
        },
    },
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                colors: {
                    primary: '#1976D2',
                    secondary: '#424242',
                    accent: '#82B1FF',
                    error: '#FF5252',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FFC107',
                },
            },
        },
    },
});
(0, vue_1.createApp)(App_vue_1.default)
    .use(base_1.store)
    .use(router_1.default)
    .use(vuetify)
    .mount('#app');
