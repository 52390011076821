"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("./base/api");
exports.default = {
    getUserSettings: function () {
        if (!localStorage.getItem('token'))
            return;
        return api_1.default.get('/user/settings');
    },
    updateUserSettings: function (settings) {
        if (!localStorage.getItem('token'))
            return;
        return api_1.default.put('/user/settings', settings);
    }
};
