"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = {
    key: 0,
    class: "address-container"
};
var _hoisted_2 = { class: "address-lines" };
var _hoisted_3 = {
    key: 0,
    class: "address-line"
};
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'AddressView',
    props: {
        companyDetails: {
            type: Object,
            default: function () { return ({}); }
        }
    },
    setup: function (__props) {
        var props = __props;
        var hasAddress = (0, vue_3.computed)(function () {
            var _a;
            var address = (_a = props.companyDetails) === null || _a === void 0 ? void 0 : _a.address;
            return (address === null || address === void 0 ? void 0 : address.address1) || (address === null || address === void 0 ? void 0 : address.address2) || (address === null || address === void 0 ? void 0 : address.address3) ||
                (address === null || address === void 0 ? void 0 : address.city) || (address === null || address === void 0 ? void 0 : address.county) || (address === null || address === void 0 ? void 0 : address.postcode);
        });
        var formattedAddress = (0, vue_3.computed)(function () {
            var _a;
            var address = (_a = props.companyDetails) === null || _a === void 0 ? void 0 : _a.address;
            if (!address)
                return [];
            var lines = [];
            if (address.address1)
                lines.push(address.address1);
            if (address.address2)
                lines.push(address.address2);
            if (address.address3)
                lines.push(address.address3);
            var cityCounty = [address.city, address.county]
                .filter(Boolean)
                .join(', ');
            if (cityCounty)
                lines.push(cityCounty);
            if (address.postcode)
                lines.push(address.postcode);
            if (address.country && address.countryIsoCode !== 'GB') {
                lines.push(address.country);
            }
            return lines;
        });
        return function (_ctx, _cache) {
            var _component_v_icon = (0, vue_2.resolveComponent)("v-icon");
            return (hasAddress.value)
                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                    (0, vue_2.createVNode)(_component_v_icon, {
                        icon: "mdi-map-marker",
                        size: "small",
                        class: "me-1"
                    }),
                    (0, vue_2.createElementVNode)("div", _hoisted_2, [
                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(formattedAddress.value, function (line) {
                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: line }, [
                                line
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_3, (0, vue_2.toDisplayString)(line), 1))
                                    : (0, vue_2.createCommentVNode)("", true)
                            ], 64));
                        }), 128))
                    ])
                ]))
                : (0, vue_2.createCommentVNode)("", true);
        };
    }
});
