"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "ticket-list" };
var _hoisted_2 = { key: 0 };
var _hoisted_3 = ["onClick"];
var _hoisted_4 = { class: "device-info" };
var _hoisted_5 = {
    key: 0,
    class: "text-grey"
};
var _hoisted_6 = { class: "d-flex justify-space-between align-center mb-2" };
var _hoisted_7 = { class: "d-flex align-center" };
var _hoisted_8 = { class: "text-h6" };
var _hoisted_9 = { class: "d-flex justify-space-between align-center" };
var _hoisted_10 = { class: "device-info" };
var _hoisted_11 = {
    key: 0,
    class: "text-grey"
};
var _hoisted_12 = {
    key: 0,
    class: "d-flex"
};
var vue_3 = require("vue");
var vuetify_1 = require("vuetify");
var vuex_1 = require("vuex");
var vue_router_1 = require("vue-router");
var date_fns_1 = require("date-fns");
var TicketDetails_vue_1 = require("./TicketDetails.vue");
var MessageList_vue_1 = require("./MessageList.vue");
var ticketApi_1 = require("@/api/ticketApi");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TicketList',
    setup: function (__props) {
        var _this = this;
        var store = (0, vuex_1.useStore)();
        var router = (0, vue_router_1.useRouter)();
        var route = (0, vue_router_1.useRoute)();
        var mobile = (0, vuetify_1.useDisplay)().mobile;
        var showMessageDialog = (0, vue_3.ref)(false);
        var messageText = (0, vue_3.ref)('');
        var selectedTicket = (0, vue_3.ref)(null);
        var messageTicket = (0, vue_3.ref)(null);
        var sending = (0, vue_3.ref)(false);
        var isMobile = (0, vue_3.computed)(function () { return mobile.value; });
        var tickets = (0, vue_3.computed)(function () { return store.getters["ticketStore/tickets"]; });
        var customerBalance = (0, vue_3.computed)(function () { return store.getters["userStore/userBalance"]; });
        var showDesktopDetails = (0, vue_3.computed)(function () {
            return !!selectedTicket.value && !showMessageDialog.value;
        });
        var balanceColorClass = (0, vue_3.computed)(function () {
            if (customerBalance.value > 0)
                return 'text-success';
            if (customerBalance.value < 0)
                return 'text-error';
            return '';
        });
        var balanceColor = (0, vue_3.computed)(function () {
            if (customerBalance.value > 0)
                return 'success';
            if (customerBalance.value < 0)
                return 'error';
            return 'grey';
        });
        var balanceIcon = (0, vue_3.computed)(function () {
            if (customerBalance.value > 0)
                return 'mdi-wallet-plus';
            if (customerBalance.value < 0)
                return 'mdi-wallet-minus';
            return 'mdi-wallet';
        });
        var headers = [
            { title: 'Ticket #', key: 'ticketNumber', width: '100px' },
            { title: 'Received', key: 'creationDate', width: '120px' },
            { title: 'Device', key: 'device', width: '200px' },
            { title: 'Status', key: 'status', width: '120px' },
            { title: 'IMEI/SN', key: 'serialNumber', width: '150px' },
            { title: 'Price', key: 'price', width: '100px' },
            { title: 'Collected/Sent', key: 'collectedDate', width: '120px' },
            { title: 'Paid', key: 'paymentStatus', width: '100px' },
            { title: 'Actions', key: 'actions', width: '80px', sortable: false },
        ];
        var visibleHeaders = (0, vue_3.computed)(function () {
            return isMobile.value ? [] : headers;
        });
        var formatDate = function (date) {
            return (0, date_fns_1.format)(new Date(date), 'dd/MM/yyyy HH:mm');
        };
        var formatPrice = function (price) {
            return new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP'
            }).format(price || 0);
        };
        var getPaymentStatusColor = function (status) {
            switch (status) {
                case 'Paid':
                    return 'success';
                case 'Partially Paid':
                    return 'warning';
                case 'Not Paid':
                    return 'error';
                default:
                    return 'grey';
            }
        };
        var handleRowClick = function (ticket) {
            if (showMessageDialog.value)
                return;
            selectedTicket.value = ticket;
            if (!isMobile.value) {
                router.replace({
                    query: __assign(__assign({}, route.query), { ticketId: ticket.id })
                }, { shallow: true });
            }
        };
        var closeDetails = function () {
            selectedTicket.value = null;
            if (!isMobile.value) {
                var query = __assign({}, route.query);
                delete query.ticketId;
                router.replace({ query: query }, { shallow: true });
            }
        };
        var handleDialogClose = function (value) {
            if (!value) {
                closeDetails();
            }
        };
        var openMessageDialog = function (ticket) {
            messageTicket.value = ticket;
            showMessageDialog.value = true;
        };
        var closeMessageDialog = function () {
            if (sending.value)
                return;
            showMessageDialog.value = false;
            messageText.value = '';
            messageTicket.value = null;
        };
        var sendMessage = function () { return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!messageText.value.trim() || !messageTicket.value)
                            return [2 /*return*/];
                        sending.value = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, ticketApi_1.default.sendMessage(messageTicket.value.id, messageText.value)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Failed to send message:', error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        sending.value = false;
                        closeMessageDialog();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var handleMessagesLoaded = function () {
            if (selectedTicket.value) {
                selectedTicket.value.unreadMessagesCount = 0;
            }
        };
        (0, vue_3.watch)(function () { return route.query.ticketId; }, function (newTicketId) { return __awaiter(_this, void 0, void 0, function () {
            var ticket;
            return __generator(this, function (_a) {
                if (newTicketId && !showMessageDialog.value) {
                    ticket = tickets.value.find(function (t) { return t.id === Number(newTicketId); });
                    if (ticket) {
                        selectedTicket.value = ticket;
                    }
                    else {
                        //todo maybe remove if not needed to load tickets in batches
                        /* try {
                           const response = await store.dispatch('ticket/fetchTicketById', newTicketId)
                           selectedTicket.value = response
                         } catch (error) {
                           console.error('Failed to fetch ticket:', error)
                           closeDetails()
                         }*/
                    }
                }
                else if (!showMessageDialog.value) {
                    selectedTicket.value = null;
                }
                return [2 /*return*/];
            });
        }); }, { immediate: true });
        (0, vue_3.onMounted)(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!((_a = tickets.value) === null || _a === void 0 ? void 0 : _a.length)) return [3 /*break*/, 2];
                        return [4 /*yield*/, store.dispatch('ticketStore/fetchTickets')];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [4 /*yield*/, store.dispatch('userStore/fetchUserSettings')];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        return function (_ctx, _cache) {
            var _component_v_icon = (0, vue_2.resolveComponent)("v-icon");
            var _component_v_card_text = (0, vue_2.resolveComponent)("v-card-text");
            var _component_v_card = (0, vue_2.resolveComponent)("v-card");
            var _component_v_chip = (0, vue_2.resolveComponent)("v-chip");
            var _component_VIcon = (0, vue_2.resolveComponent)("VIcon");
            var _component_v_btn = (0, vue_2.resolveComponent)("v-btn");
            var _component_VTooltip = (0, vue_2.resolveComponent)("VTooltip");
            var _component_v_alert = (0, vue_2.resolveComponent)("v-alert");
            var _component_v_data_table = (0, vue_2.resolveComponent)("v-data-table");
            var _component_v_card_title = (0, vue_2.resolveComponent)("v-card-title");
            var _component_v_textarea = (0, vue_2.resolveComponent)("v-textarea");
            var _component_v_spacer = (0, vue_2.resolveComponent)("v-spacer");
            var _component_v_card_actions = (0, vue_2.resolveComponent)("v-card-actions");
            var _component_v_dialog = (0, vue_2.resolveComponent)("v-dialog");
            var _component_v_toolbar_title = (0, vue_2.resolveComponent)("v-toolbar-title");
            var _component_v_toolbar = (0, vue_2.resolveComponent)("v-toolbar");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)(_component_v_card, {
                    class: "mb-4 balance-card",
                    elevation: "2"
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_v_card_text, { class: "d-flex align-center justify-space-between" }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createElementVNode)("div", null, [
                                    _cache[3] || (_cache[3] = (0, vue_2.createElementVNode)("div", { class: "text-subtitle-1 font-weight-medium" }, "Account Balance", -1)),
                                    (0, vue_2.createElementVNode)("div", {
                                        class: (0, vue_2.normalizeClass)(["text-h5", balanceColorClass.value])
                                    }, (0, vue_2.toDisplayString)(formatPrice(customerBalance.value)), 3)
                                ]),
                                (0, vue_2.createVNode)(_component_v_icon, {
                                    icon: balanceIcon.value,
                                    color: balanceColor.value,
                                    size: "large"
                                }, null, 8, ["icon", "color"])
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createVNode)(_component_v_data_table, {
                    headers: visibleHeaders.value,
                    items: tickets.value,
                    "sort-by": [{ key: 'creationDate', order: 'desc' }],
                    class: (0, vue_2.normalizeClass)(["elevation-1 ticket-table", { 'mobile-view': isMobile.value }])
                }, (0, vue_2.createSlots)({
                    header: (0, vue_2.withCtx)(function (_a) {
                        var columns = _a.columns;
                        return [
                            (!isMobile.value)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("thead", _hoisted_2, [
                                    (0, vue_2.createElementVNode)("tr", null, [
                                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(columns, function (header) {
                                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("th", {
                                                key: header.key,
                                                style: (0, vue_2.normalizeStyle)({ width: header.width })
                                            }, (0, vue_2.toDisplayString)(header.title), 5));
                                        }), 128))
                                    ])
                                ]))
                                : (0, vue_2.createCommentVNode)("", true)
                        ];
                    }),
                    _: 2
                }, [
                    (!isMobile.value)
                        ? {
                            name: "item",
                            fn: (0, vue_2.withCtx)(function (_a) {
                                var item = _a.item;
                                return [
                                    (0, vue_2.createElementVNode)("tr", {
                                        class: (0, vue_2.normalizeClass)({ 'unread-messages': item.unreadMessagesCount > 0 }),
                                        onClick: function ($event) { return (handleRowClick(item)); }
                                    }, [
                                        (0, vue_2.createElementVNode)("td", null, [
                                            (0, vue_2.createTextVNode)(" #" + (0, vue_2.toDisplayString)(item.ticketNumber) + " ", 1),
                                            (item.unreadMessagesCount > 0)
                                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_chip, {
                                                    key: 0,
                                                    color: "info",
                                                    size: "x-small",
                                                    class: "ms-2"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(item.unreadMessagesCount), 1)
                                                    ]; }),
                                                    _: 2
                                                }, 1024))
                                                : (0, vue_2.createCommentVNode)("", true)
                                        ]),
                                        (0, vue_2.createElementVNode)("td", null, (0, vue_2.toDisplayString)(formatDate(item.creationDate)), 1),
                                        (0, vue_2.createElementVNode)("td", null, [
                                            (0, vue_2.createElementVNode)("div", _hoisted_4, [
                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(item.deviceBrand) + " " + (0, vue_2.toDisplayString)(item.deviceModel) + " ", 1),
                                                (item.deviceColor)
                                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_5, " (" + (0, vue_2.toDisplayString)(item.deviceColor) + ") ", 1))
                                                    : (0, vue_2.createCommentVNode)("", true)
                                            ])
                                        ]),
                                        (0, vue_2.createElementVNode)("td", null, [
                                            (0, vue_2.createVNode)(_component_v_chip, {
                                                color: item.ticketStatus.color,
                                                "text-color": item.ticketStatus.textColor,
                                                size: "small"
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(item.ticketStatus.name), 1)
                                                ]; }),
                                                _: 2
                                            }, 1032, ["color", "text-color"])
                                        ]),
                                        (0, vue_2.createElementVNode)("td", null, (0, vue_2.toDisplayString)(item.serialNumber), 1),
                                        (0, vue_2.createElementVNode)("td", null, (0, vue_2.toDisplayString)(formatPrice(item.price)), 1),
                                        (0, vue_2.createElementVNode)("td", null, (0, vue_2.toDisplayString)(item.collectedDate ? formatDate(item.collectedDate) : '-'), 1),
                                        (0, vue_2.createElementVNode)("td", null, [
                                            (0, vue_2.createVNode)(_component_v_chip, {
                                                color: getPaymentStatusColor(item.paymentStatus),
                                                size: "small"
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(item.paymentStatus), 1)
                                                ]; }),
                                                _: 2
                                            }, 1032, ["color"])
                                        ]),
                                        (0, vue_2.createElementVNode)("td", null, [
                                            (0, vue_2.createVNode)(_component_VTooltip, {
                                                text: " Send Message",
                                                location: "top"
                                            }, {
                                                activator: (0, vue_2.withCtx)(function (_a) {
                                                    var props = _a.props;
                                                    return [
                                                        (0, vue_2.createVNode)(_component_v_btn, (0, vue_2.mergeProps)({
                                                            onClick: (0, vue_2.withModifiers)(function ($event) { return (openMessageDialog(item)); }, ["stop"])
                                                        }, props, {
                                                            variant: "text",
                                                            color: "primary"
                                                        }), {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                (0, vue_2.createVNode)(_component_VIcon, null, {
                                                                    default: (0, vue_2.withCtx)(function () { return _cache[4] || (_cache[4] = [
                                                                        (0, vue_2.createTextVNode)("mdi-message-outline")
                                                                    ]); }),
                                                                    _: 1
                                                                })
                                                            ]; }),
                                                            _: 2
                                                        }, 1040, ["onClick"])
                                                    ];
                                                }),
                                                _: 2
                                            }, 1024)
                                        ])
                                    ], 10, _hoisted_3)
                                ];
                            }),
                            key: "0"
                        }
                        : {
                            name: "item",
                            fn: (0, vue_2.withCtx)(function (_a) {
                                var item = _a.item;
                                return [
                                    (0, vue_2.createVNode)(_component_v_card, {
                                        class: "mb-2",
                                        onClick: function ($event) { return (handleRowClick(item)); }
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)(_component_v_card_text, null, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createElementVNode)("div", _hoisted_6, [
                                                        (0, vue_2.createElementVNode)("div", _hoisted_7, [
                                                            (0, vue_2.createElementVNode)("span", _hoisted_8, "#" + (0, vue_2.toDisplayString)(item.ticketNumber), 1),
                                                            (item.unreadMessagesCount > 0)
                                                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_chip, {
                                                                    key: 0,
                                                                    color: "info",
                                                                    size: "x-small",
                                                                    class: "ms-2"
                                                                }, {
                                                                    default: (0, vue_2.withCtx)(function () { return [
                                                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(item.unreadMessagesCount), 1)
                                                                    ]; }),
                                                                    _: 2
                                                                }, 1024))
                                                                : (0, vue_2.createCommentVNode)("", true)
                                                        ]),
                                                        (0, vue_2.createVNode)(_component_v_chip, {
                                                            color: item.ticketStatus.color,
                                                            "text-color": item.ticketStatus.textColor,
                                                            size: "small"
                                                        }, {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(item.ticketStatus.name), 1)
                                                            ]; }),
                                                            _: 2
                                                        }, 1032, ["color", "text-color"])
                                                    ]),
                                                    (0, vue_2.createElementVNode)("div", _hoisted_9, [
                                                        (0, vue_2.createElementVNode)("div", _hoisted_10, [
                                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(item.deviceBrand) + " " + (0, vue_2.toDisplayString)(item.deviceModel) + " ", 1),
                                                            (item.deviceColor)
                                                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_11, " (" + (0, vue_2.toDisplayString)(item.deviceColor) + ") ", 1))
                                                                : (0, vue_2.createCommentVNode)("", true)
                                                        ]),
                                                        (0, vue_2.createElementVNode)("div", null, (0, vue_2.toDisplayString)(formatPrice(item.price)), 1)
                                                    ])
                                                ]; }),
                                                _: 2
                                            }, 1024)
                                        ]; }),
                                        _: 2
                                    }, 1032, ["onClick"])
                                ];
                            }),
                            key: "1"
                        },
                    (tickets.value && tickets.value.length === 0)
                        ? {
                            name: "no-data",
                            fn: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(_component_v_alert, {
                                    type: "info",
                                    variant: "tonal",
                                    class: "ma-2"
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return _cache[5] || (_cache[5] = [
                                        (0, vue_2.createTextVNode)(" No tickets found ")
                                    ]); }),
                                    _: 1
                                })
                            ]; }),
                            key: "2"
                        }
                        : undefined
                ]), 1032, ["headers", "items", "class"]),
                (0, vue_2.createVNode)(_component_v_dialog, {
                    modelValue: showMessageDialog.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((showMessageDialog).value = $event); }),
                    "max-width": "500px",
                    persistent: sending.value,
                    scrim: true,
                    "retain-focus": false,
                    transition: "dialog-bottom-transition"
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_v_card, null, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(_component_v_card_title, { class: "text-h6" }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        _cache[6] || (_cache[6] = (0, vue_2.createTextVNode)(" Send Message ")),
                                        (0, vue_2.createVNode)(_component_v_btn, {
                                            icon: "mdi-close",
                                            variant: "text",
                                            size: "small",
                                            onClick: closeMessageDialog,
                                            class: "float-right"
                                        })
                                    ]; }),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)(_component_v_card_text, null, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)(_component_v_textarea, {
                                            modelValue: messageText.value,
                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((messageText).value = $event); }),
                                            label: "Message",
                                            rows: "4",
                                            "auto-grow": "",
                                            "hide-details": "",
                                            class: "mt-4"
                                        }, null, 8, ["modelValue"])
                                    ]; }),
                                    _: 1
                                }),
                                (0, vue_2.createVNode)(_component_v_card_actions, null, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)(_component_v_spacer),
                                        (0, vue_2.createVNode)(_component_v_btn, {
                                            color: "primary",
                                            onClick: sendMessage,
                                            loading: sending.value,
                                            disabled: !messageText.value.trim()
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return _cache[7] || (_cache[7] = [
                                                (0, vue_2.createTextVNode)(" Send ")
                                            ]); }),
                                            _: 1
                                        }, 8, ["loading", "disabled"]),
                                        (0, vue_2.createVNode)(_component_v_btn, {
                                            color: "grey",
                                            variant: "text",
                                            onClick: closeMessageDialog,
                                            disabled: sending.value
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return _cache[8] || (_cache[8] = [
                                                (0, vue_2.createTextVNode)(" Cancel ")
                                            ]); }),
                                            _: 1
                                        }, 8, ["disabled"])
                                    ]; }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                }, 8, ["modelValue", "persistent"]),
                (0, vue_2.createVNode)(_component_v_dialog, {
                    modelValue: showDesktopDetails.value,
                    "onUpdate:modelValue": [
                        _cache[2] || (_cache[2] = function ($event) { return ((showDesktopDetails).value = $event); }),
                        handleDialogClose
                    ],
                    width: "800",
                    fullscreen: isMobile.value,
                    "retain-focus": false,
                    scrim: true,
                    transition: "dialog-transition"
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_v_card, null, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (isMobile.value)
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_v_toolbar, {
                                        key: 0,
                                        color: "primary"
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createVNode)(_component_v_btn, {
                                                icon: "mdi-close",
                                                onClick: closeDetails
                                            }),
                                            (0, vue_2.createVNode)(_component_v_toolbar_title, null, {
                                                default: (0, vue_2.withCtx)(function () { return _cache[9] || (_cache[9] = [
                                                    (0, vue_2.createTextVNode)("Ticket Details")
                                                ]); }),
                                                _: 1
                                            })
                                        ]; }),
                                        _: 1
                                    }))
                                    : (0, vue_2.createCommentVNode)("", true),
                                (0, vue_2.createVNode)(_component_v_card_text, { class: "pa-0" }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (!isMobile.value)
                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_12, [
                                                (0, vue_2.createVNode)(_component_v_btn, {
                                                    icon: "mdi-close",
                                                    variant: "text",
                                                    size: "small",
                                                    class: "close-button",
                                                    onClick: closeDetails
                                                })
                                            ]))
                                            : (0, vue_2.createCommentVNode)("", true),
                                        (selectedTicket.value)
                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(TicketDetails_vue_1.default, {
                                                key: 1,
                                                ticket: selectedTicket.value
                                            }, null, 8, ["ticket"]))
                                            : (0, vue_2.createCommentVNode)("", true),
                                        (selectedTicket.value)
                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(MessageList_vue_1.default, {
                                                key: 2,
                                                ticket: selectedTicket.value,
                                                ticketId: selectedTicket.value.id,
                                                onLoaded: handleMessagesLoaded
                                            }, null, 8, ["ticket", "ticketId"]))
                                            : (0, vue_2.createCommentVNode)("", true)
                                    ]; }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                }, 8, ["modelValue", "fullscreen"])
            ]));
        };
    }
});
